import service from '@/until/api'
// 查询所有菜单
export function _FindAllPage(data) {
    return service({
        url: '/system-nacos/menuCenter/findAll',
        method: 'GET',
        params: data
    })
}


//菜单管理页面---删除菜单
export function _DeleteMenuCenter(data) {
    return service({
        url: '/system-nacos/menu/deleteMenuCenter',
        method: 'GET',
        params: data
    })
}

//菜单管理页面---添加菜单

export function _AddMenuCenter(data) {
    return service({
      url: '/system-nacos/menu/addMenu',
      method: 'POST',
      data
    })
  }

  //菜单管理页面---编辑菜单

export function _UpdateMenuCenter(data) {
    return service({
      url: '/system-nacos/menu/updateMenuPark',
      method: 'POST',
      data
    })
  }



